import React from "react";
import '../styles/components/ServicesSection.css';
import logo from "../resources/mediaboost-blanco.gif"
import image_above from "../resources/shapes/094501087b.gif"
import image_below from "../resources/shapes/07ba2e6363.gif"
import { useNavigate } from "react-router-dom";

const ServicesSection = () => {
    const navigate = useNavigate();

    const handleButtonRedirection = () => {
        navigate("/form")
    }
    
    return (
        <section className="container full-vh" id="servicios-ref">
            <div id="services">
                <div id="services-image">
                    <img data-aos="fade" id="services-image-logo" className="centered-absolute" src={logo} alt="" />
                    <img id="services-image-above" className="centered-absolute" src={image_above} alt="" />
                    <img id="services-image-below" className="centered-absolute" src={image_below} alt="" />
                </div>
                <div id="services-content">
                    <div data-aos="fade-left" className="service">
                        <h4>Analítica y Reportes</h4>
                        <p>Realizamos monitoreo y análisis profundos del rendimiento de las campañas, lo que nos permite identificar oportunidades, ajustar las estrategias y de mejorar los resultados. </p>
                    </div>
                    <div data-aos="fade-left" className="service">
                        <h4>Crecimiento de Audiencias</h4>
                        <p>Implementamos estrategias personalizadas para incrementar la audiencia y potenciar el engagement en plataformas de streaming como YouTube, Twitch, Kick, entre otras.</p>
                    </div>
                    <button data-aos="fade-left" onClick={handleButtonRedirection}>Contratar servicio</button>
                </div>
            </div>
        </section>
    )
};

export default ServicesSection;