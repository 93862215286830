import React, { useState } from 'react';
import '../styles/components/Navbar.css'
import logo from "../resources/mediaboost-blanco.gif";

const Navbar = () => {
    const [navLinksActive, setNavLinksActive] = useState(false);
    const [navActive, setNavActive] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
  
    const handleNavClick = () => {
      setNavLinksActive(!navLinksActive);
      setIsExpanded(!isExpanded);
      setNavActive(!navActive);
    };

	return (
        <>
            <div className={navActive && "blur"}></div>
            <nav className={navActive ? "active" : ""}>
            <div className="nav-container">
                <a href="#" className="logo-link" aria-label="Go to homepage">
                    <img src={logo} alt="mediaboost logo" />
                </a>
                <div className="nav-wrapper">
                    <button className="btn btn--menu" id="menu-btn" aria-expanded={isExpanded} aria-controls="menu" aria-label="Open mobile nav" onClick={handleNavClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" fill="none" viewBox="0 0 24 24" stroke="#ad2f98" strokeWidth="4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                        </svg>
                    </button>
                    <ul role="menubar" className={navLinksActive? "nav-links activated" : "nav-links"} id="menu">
                        <li role="none">
                            <a role="menuitem" onClick={handleNavClick} href="#inicio-ref" className="nav-link btn">Inicio</a>
                        </li>
                        <li role="none">
                            <a role="menuitem" onClick={handleNavClick} href="#servicios-ref" className="nav-link btn">Servicios</a>
                        </li>
                        <li role="none">
                            <a role="menuitem" onClick={handleNavClick} href="#contact-ref" className="nav-link btn">Contacto</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
	);
};

export default Navbar;
