import emailjs from 'emailjs-com'; // Asumiendo que estás usando EmailJS
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://pajlritdsmzamqpklekz.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBhamxyaXRkc216YW1xcGtsZWt6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY0MTQzNjAsImV4cCI6MjA0MTk5MDM2MH0.4_kPU5gOOHaKV0yppVclcj-i5BQ2sQiHh_aYbR7MqUk';
const supabase = createClient(supabaseUrl, supabaseKey);

const sendForm = async (formData) => {
    await emailjs.send(
      'service_ixt873f',
      'template_9sn498k',
      formData,
      'lXuy69nZpE02-4360'
    );
    
    await supabase.from('form_submission')
      .insert([formData]);
};

export { sendForm };
