import React, { useState } from "react";
import '../styles/components/ContactForm.css';
import { sendForm } from "../utils/FormService";
import ConfirmationModal from './ConfirmationModal';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};

        if (!formData.name) {
            formErrors.name = "El nombre es obligatorio.";
        }

        if (!formData.email) {
            formErrors.email = "El email es obligatorio.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "El email no es válido.";
        }

        if (!formData.message) {
            formErrors.message = "El mensaje es obligatorio.";
        }

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            sendForm({
                name: formData.name,
                email: formData.email,
                message: formData.message,
                selected_services: ""
            });

            setFormData({
                name: "",
                email: "",
                message: "",
            });
            setErrors({});

            setEmailSent(true);
            setTimeout(() => {
              setEmailSent(false);
            }, 3000);
        }
    };

    return (
        <>
            <form data-aos="fade" onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="name">Nombre:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={errors.name && "input-error"}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                </div>

                <div className="input-container">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email && "input-error"}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>

                <div className="textarea-container">
                    <label htmlFor="message">Mensaje:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={errors.message && "input-error"}
                    />
                    {errors.message && <p className="error">{errors.message}</p>}
                </div>

                <button style={{width: "100%"}} type="submit">Enviar</button>
            </form>
            <ConfirmationModal visible={emailSent} message={"Formulario enviado!"} onClose={() => setEmailSent(false)}></ConfirmationModal>
        </>
    );
};

export default ContactForm;
