import React from "react";
import '../styles/components/MarketingSection.css';
import image_one from "../resources/img-3.webp"
import image_two from "../resources/img-5.webp"

const MarketingSection = () => {
    return (
        <section className="container full-vh">
            <div id="marketing">
                <h2>Marketing de contenidos</h2>
                <img data-aos="fade-right" id="marketing-img-one" src={image_one} alt="" />
                <img data-aos="fade-left" id="marketing-img-two" src={image_two} alt="" />
                <p id="marketing-main-paragraph" data-aos="fade-up">Creación de contenido relevante y valioso para atraer y retener una audiencia.</p>
                <p id="marketing-sub-paragraph" data-aos="fade-up">Desarrollamos y creamos contenido estratégicos, relevantes y valiosos cuidadosamente diseñado para atraer y retener la audiencia.</p>
            </div>
        </section>
    )
};

export default MarketingSection;