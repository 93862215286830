import React from "react";
import { useNavigate } from 'react-router-dom';
import '../styles/components/ProductSection.css';
import background_image from "../resources/shapes/45cb848511.gif"

const ProductSection = ({title, paragraph, video, isRotated}) => {
    const navigate = useNavigate();

    const handleButtonRedirection = () => {
        navigate('/form');
      };

	return (
        <section className="container full-vh">
            <div className="product">
                <div data-aos="fade-up" className="product-text">
                    <h2>{title}</h2>
                    <p>{paragraph}</p>
                    <button onClick={handleButtonRedirection}>Contratar servicio</button>
                </div>
                <div data-aos="fade-left" className={isRotated? "product-video product-video-rotated" : "product-video"}>
                    <img data-aos-delay="200"  src={background_image}  alt="" />
                    <video loop autoPlay muted>
                        <source src={video} type="video/mp4" />
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                </div>
            </div>
        </section>
	);
};

export default ProductSection;