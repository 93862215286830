import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/containers/Form.css"
import ServicesForm from '../components/ServicesForm';
import logo from '../resources/mediaboost-blanco.gif'
import image_above from "../resources/shapes/094501087b.gif"
import image_below from "../resources/shapes/07ba2e6363.gif"

function Form() {
  return (
    <div className="container">
      <div id="service-form-container">
        <Link to="/">
          <img className="logo" src={logo} alt="" />
        </Link>
        <h1>Servicio AMB</h1>
        <div id="service-form-images">
          <img id="services-form-image-above" className="centered-absolute" src={image_above} alt="" />
          <img id="services-form-image-below" className="centered-absolute" src={image_below} alt="" />
        </div>
        <ServicesForm></ServicesForm>
      </div>
    </div>
  );
}

export default Form;