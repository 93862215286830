import React, { useState } from 'react';
import "../styles/components/ServicesForm.css"
import { sendForm } from '../utils/FormService';
import ConfirmationModal from './ConfirmationModal';

const options = {
    "Boost User Consumption": { 
      description: "Plataforma en la que desea contratar el servicio.", 
      subOptions: ['Spotify', 'Apple Music', 'Deezer', "Twitch", "Spotify (Autoaprendizaje del comportamiento del usuario)", "YouTube (Autoaprendizaje del comportamiento del usuario)"] 
    },
    "MKT Mailing": { 
      description: "Elija la cantidad de correos a contratar.", 
      subOptions: ['1 a 1000', '1001 a 5000', '5001 a 10000'] 
    },
    "Net Manager": { 
      description: "Creamos y hacemos seguimiento de los estados y notificaciones en todas las plataformas. Creación y manejo de perfiles en redes sociales.", 
      subOptions: ['1 Plataforma S/YouTube', 'YouTube', '2 Plataformas', 'Más de 2 Plataformas mismo usuario'] 
    },
    "PPC": { 
      description: "Gestión de anuncios pagados en plataformas como Google Ads y redes sociales.", 
      subOptions: ['Contratar servicio PPC'] 
    },
    "SEO": { 
      description: "Optimización de sitios web para mejorar su visibilidad en motores de búsqueda como Google.", 
      subOptions: ['Contratar servicio SEO'] 
    },
    "Otros": { 
      description: "Solicite más información sobre otros servicios.", 
      subOptions: ['Pedir mas informacion'] 
    },
  };
function ServicesForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        option: "",
        selectedServices: []
    });

    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};

        if (!formData.name) {
            formErrors.name = "El nombre es obligatorio.";
        }

        if (!formData.email) {
            formErrors.email = "El email es obligatorio.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "El email no es válido.";
        }

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const handleOptionClick = (option) => {
        setFormData({
            ...formData,
            option: option,
        })
    };

    const handleSubOptionClick = (subOption) => {
        if (formData.selectedServices.includes(subOption)) {
            setFormData({
                ...formData,
                selectedServices: formData.selectedServices.filter(value => value !== subOption)
            })
        } else {
            setFormData({
                ...formData,
                selectedServices: [...formData.selectedServices, subOption]
            })
        }
    };

    const handleBackClick = () => {
        setFormData({
            ...formData,
            option: '',
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            sendForm({
                name: formData.name,
                email: formData.email,
                message: "-",
                selected_services: mapSelectedOptions()
            });

            setFormData({
                name: "",
                email: "",
                option: "",
                selectedServices: []
            });
            setErrors({});

            setEmailSent(true);
            setTimeout(() => {
              setEmailSent(false);
            }, 3000);
        }
    };

    const mapSelectedOptions = () => {
        return Object.entries(options)
          .map(([optionKey, optionValue]) => {    
            const selectedSubOptions = optionValue.subOptions.filter(subOption => formData.selectedServices.includes(subOption));
    
            if (selectedSubOptions.length > 0) {
              return `${optionKey}: ${selectedSubOptions.join(', ')}`;
            }
    
            return null;
          })
          .filter(Boolean)
          .join(' --- ');
      };

    return (
        <form className='service-form' onSubmit={handleSubmit}>
            <div className="input-container service-input">
                <label htmlFor="name">Nombre y apellido</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={errors.name && "input-error"} />
                {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="input-container service-input">
                <label htmlFor="email">Email de contacto</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={errors.email && "input-error"} />
                {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <h4>Que servicio desea contratar?</h4>
            <div className='options-container'>
                {formData.option ? (
                    <>
                        <div className='selected-info'>
                            <button type='button' onClick={handleBackClick} className="back-button">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </button>
                            <h2 id='selected-option'>{formData.option}</h2>
                        </div>

                        <div className='suboptions'>
                            {options[formData.option].subOptions.map((subOption) => (
                                <button
                                    type='button'
                                    key={subOption}
                                    className={formData.selectedServices.includes(subOption) ? "suboption-button selected" : "suboption-button"}
                                    onClick={() => handleSubOptionClick(subOption)}>
                                    {subOption}
                                </button>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className='options'>
                        {Object.keys(options).map((option) => (
                            <button
                                key={option}
                                type='button'
                                onClick={() => handleOptionClick(option)}
                                className="option-button">
                                {option}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {formData.selectedServices.length > 0 && (
                <div className='service-form-submit-button'>
                    <button type="submit">
                        Enviar
                    </button>
                    <p >
                        Enviar para que un representante se ponga en contacto via email
                    </p>
                </div>

            )}

            <ConfirmationModal visible={emailSent} message={"Formulario enviado!"} onClose={() => setEmailSent(false)}></ConfirmationModal>
        </form>
    );
}

export default ServicesForm;