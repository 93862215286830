import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Form from "./containers/Form";
import ScrollToTop from "./utils/ScrollToTop";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out', 
      once: true,
    });
  }, []);

	return (
		<BrowserRouter>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/form" element={<Form></Form>} />
      </Routes>
		</BrowserRouter>
	);
};

export default App;
