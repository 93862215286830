import React from 'react';
import '../styles/components/ConfirmationModal.css';


const ConfirmationModal = ({ visible, message, onClose }) => {
  return (
    <div className={`confirmation-modal ${visible ? 'show' : ''}`}>
      <div className="modal-content">
        <button onClick={onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ConfirmationModal;