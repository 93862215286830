import React from "react";
import '../styles/components/OfferingSection.css';
import image_above from "../resources/shapes/094501087b.gif"
import image_below from "../resources/shapes/1b0d7efe92.gif"

const OfferingSection = () => {
	return (
        <section className="container full-vh">
            <div id="offering">
                <div data-aos="fade-right" id="offering-image">
                    <img data-aos="fade" data-aos-delay="100" id="offering-image-above" className="centered-absolute" src={image_above} alt="" />
                    <img id="offering-image-below" className="centered-absolute" src={image_below} alt="" />
                </div>
                <div>
                    <h3 data-aos="fade-up">Que ofrecemos</h3>
                    <p data-aos="fade-up" id="offering-paragraph">
                        Brindamos una amplia gama de servicios diseñados para mejorar la experiencia del usuario, ademas de fortalecer la presencia en línea y aumentar la audiencia de nuestros clientes
                    </p>
                </div>
            </div>
        </section>
	);
};

export default OfferingSection;