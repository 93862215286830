import React from "react";
import Header from "../components/Header";
import OfferingSection from "../components/OfferingSection";
import ProductSection from "../components/ProductSection";
import video_1 from '../resources/videos/marketing_digital.webm';
import video_2 from '../resources/videos/seo.webm';
import video_3 from '../resources/videos/ads2.webm';
import video_4 from '../resources/videos/trending.webm';
import video_5 from '../resources/videos/email.webm';
import MarketingSection from "../components/MarketingSection";
import ServicesSection from "../components/ServicesSection";
import ContactSection from "../components/ContactSection";
import Navbar from "../components/Navbar";

const Home = () => {
	return (
		<>
		    <Navbar></Navbar>
			<Header></Header>
			<OfferingSection></OfferingSection>
			<ProductSection 
				title={"Estrategias de Marketing Digital"} 
				paragraph={"Planificación y ejecución de campañas de marketing en múltiples plataformas digitales para maximizar el impacto y alcanzar objetivos específicos."}
				video={video_1}>
			</ProductSection>
			<ProductSection 
				title={"SEO (Search Engine Optimization)"} 
				paragraph={"Optimización de sitios web para mejorar su visibilidad en motores de búsqueda como Google."}
				video={video_2}
				isRotated>
			</ProductSection>
			<ProductSection 
				title={"Publicidad pagada (PPC)"} 
				paragraph={"Gestión de anuncios pagados en plataformas como Google Ads y redes sociales."}
				video={video_3}
				isRotated>
			</ProductSection>
			<ProductSection 
				title={"BOOST User Consumption"} 
				paragraph={"Impulsa orgánicamente escuchas , vistas, Like y/o Suscriptores o me gustas."}
				video={video_4}
				isRotated>
			</ProductSection>
			<MarketingSection></MarketingSection>
			<ProductSection 
				title={"Email marketing"} 
				paragraph={"Diseño y ejecución de campañas de correo electrónico para mantener a la audiencia informada y comprometida."}
				video={video_5}
				isRotated>
			</ProductSection>
			<ServicesSection></ServicesSection>
			<ContactSection></ContactSection>
		</>

	);
};

export default Home;