import React from "react";
import '../styles/components/Header.css';
import image_above from "../resources/shapes/07ba2e6363.gif"
import image_below from "../resources/shapes/07eb5016af.gif"

const Header = () => {
	return (
		<header className="container full-vh" id="inicio-ref">
            <div id="hero-section">
                <h1 data-aos="fade-up" className="hero-section-title">Action Media Boost</h1>
                <p data-aos-delay="100" data-aos="fade-up" id="hero-section-paragraph">Acciones que impulsan tus contenidos</p>
                <div data-aos="fade-left" id="hero-section-images">
                    <img id="hero-section-image-above" className="centered-absolute" src={image_above} alt="" />
                    <img id="hero-section-image-below" className="centered-absolute" src={image_below} alt="" />
                </div>
            </div>
		</header>
	);
};

export default Header;