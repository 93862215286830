import React from "react";
import '../styles/components/ContactSection.css';
import image_above from "../resources/shapes/094501087b.gif";
import image_below from "../resources/shapes/1b0d7efe92.gif";
import ContactForm from "./ContactForm";

const ContactSection = () => {
    return (
        <section className="container full-vh" id="contact-ref">
            <div id="contact">
                <div id="contact-content">
                    <h3>Contactanos</h3>
                    <ContactForm></ContactForm>
                    <div id="contacts">
                        <div>
                            <h5>Email Contratacion</h5>
                            <a href="mailto:"><i className="fa fa-envelope"></i> buy@actionmediaboost.com</a>
                        </div>
                        <div>
                            <h5>Email Seguimiento</h5>
                            <a href="mailto:"><i className="fa fa-envelope"></i> follow@actionmediaboost.com</a>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" id="contact-image">
                    <img id="contact-image-above" src={image_above} alt="" />
                    <img id="contact-image-below" src={image_below} alt="" />
                </div>
            </div>
        </section>
    )
};

export default ContactSection;